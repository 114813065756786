.Home {
    .hero-section {
        // background: url('../../../asset/FondImg.webp') no-repeat center center/cover;
        background: url('../../../asset/5.webp')  center/cover;
        color: #fff;
        padding: 100px 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 500px; 

        .hero-content {
            background: #fff;
            padding: 40px 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            max-width: 800px;
            text-align: center;
            color: #004080;
            position: absolute; 
            top: 76%; 
            transform: translateY(-50%); 


            h1 {
                font-size: 2.5em;
                margin-bottom: 20px;
            }

            p {
                font-size: 1.2em;
                margin-bottom: 20px;
              //  color: #333;
            }

            .cta-button {
                background-color: #0073e6;
                color: #fff;
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 1.2em;
                margin-top: 20px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease; 

                &:hover {
                    background-color: #005bb5; 
                    transform: translateY(-3px); 
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
                }

                &:active {
                    background-color: #004080; 
                    transform: translateY(1px); 
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
                }
            }

            .welcome-message {
               margin-block-start: 0px !important;
                font-size: 1.5em;
                margin-top: 20px;
                color: #0073e6;
            }
        }
    }

    .card-section {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 40px 0;

        .card {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            padding: 30px;
            margin: 20px;
            flex: 1;
            max-width: 300px;
            text-align: center;

            h3 {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 1.5em;
                margin-bottom: 15px;
                color: #004080;

                .icon {
                    font-size: 2em;
                    margin-bottom: 10px;
                    color: #0073e6;
                }
            }
            p {
                font-size: 1em;
                margin-bottom: 15px;
               // color: #333;
            }

            .card-button {
                background-color: #004080;
                color: #fff;
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 1em;
                margin-top: 15px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease; 

                &:hover {
                    background-color: #002f5b; 
                    transform: translateY(-3px); 
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
                }

                &:active {
                    background-color: #003a73;
                    transform: translateY(1px); 
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
                }
            }
        }
    }
    @media (max-width: 768px) {
        .hero-section .hero-content {
            top: 50%;
        
        h1{
            font-size: 1.5em;
        }
    }
        }
}
