* {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
}

.error-message {
    color: red;
    margin-top: 5px;
    font-size: 12px;
}

.helper-message {
    color: #666;
    margin-top: 5px;
    font-size: 12px;
}

.signup-container h2 {
    margin-bottom: 20px;
    color: #333;
}

.signup-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
}

.signup-container label {
    font-weight: bold;
    margin-bottom: 5px;
}

.signup-container input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.signup-container button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
}

.signup-container button:hover {
    background-color: #005b9f;
}

.modal-content {
    padding: 20px;
    text-align: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
