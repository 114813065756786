* {
  box-sizing: border-box;
}

.containerProfils {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  width: 100%;
}

.Profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1800px;
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab.active {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  color: $primary-color;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tab:hover:not(.active) {
  color: $primary-color;
}

.data, .Optin, .Modify, .company-data {
  width: 100%;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

h1 {
  color: $primary-color;
  text-align: center;
  margin-bottom: 20px;
}

p {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  word-wrap: break-word;


}

.error {
  color: red;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
  width: 100%;
}

form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  align-items: center;
}

form label span {
  display: flex;
  align-items: center;
  gap: 5px;
}

form label i {
  color: $primary-color;
}

form input {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

form button {
  grid-column: 1 / -1;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

form button:hover {
  background-color: $background-color;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.info, .Modify form, .company-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
}

.info p, .Modify label, .company-info div {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 0;


}

.info p span, .Modify label span, .company-info div span {
//  font-weight: bold;
  color: $primary-color;
}

.Optin {
  width: 100%;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Optin h1 {
  color: $primary-color;
  text-align: center;
  margin-bottom: 10px;
}

.Optin p {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;
}

.Optin form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Optin form input[type="email"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.Optin form button {
  padding: 10px 20px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Optin form button:hover {
  background-color: darken($primary-color, 10%);
}

@media (max-width: 768px) {
  .containerProfils {
    width: 100%;
    padding: 10px;
  }

  .tabs {
    flex-direction: column;
    align-items: center;
  }

  .info, .Modify form, .company-info {
    grid-template-columns: 1fr;
  }

  form button {
    width: 100%;
  }
}
