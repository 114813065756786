@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
    position: fixed;
    top: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    z-index: 100;
    background-color: $background-color;
    width: 30%;
    text-align: center;
    border-radius: 10px;
    position: relative;
    padding: 5%;
}

.text {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.close-modal {
    position: absolute;
    top: 8px;
    right: 14px;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}