.Footer {
    background-color: #004080; // Couleur de fond similaire à l'en-tête
    color: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    p {
        color: #ffffff;
    }

    .footer-section {
        flex: 1;
        margin: 10px 20px;
        min-width: 200px; // Largeur minimale pour uniformiser les sections
        align-self: center;

        img {
            max-width: 150px; 
            height: auto;
        }
    }

    .footer-section h3 {
        margin-bottom: 10px;
        font-size: 1.2em;
    }

    .footer-section p,
    .footer-section a {
        margin-bottom: 10px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.3s ease;
    }

    .footer-section a:hover {
        color: #c0c0c0;
    

    }

    .footer-section ul {
        list-style-type: none;
        line-height: 25px;
    }
    .social-icons {
        display: flex;
        gap: 10px;
        margin-top: 10px; // Ajouté pour mieux aligner avec les autres sections

        a {
            color: #ffffff;
            font-size: 1.5em;
        }

        a:hover {
            color: #005b9f;
        }
    }

    .footer-bottom {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        border-top: 1px solid #ffffff;
        padding-top: 10px;

        img {
            max-width: 100px; // Taille de l'image réduite pour les petits écrans
            height: auto;
        }
    }

    @media (max-width: 768px) {
        .Footer {
            flex-direction: column;
            align-items: center;
        }

        .footer-section {
            text-align: center;
            margin: 10px 0; // Marges pour espacement vertical
        }

        .social-icons {
            justify-content: center; // Centrer les icônes des réseaux sociaux sur mobile
        }

        .footer-bottom {
            margin-top: 10px;

            img {
                max-width: 80px; // Taille de l'image encore plus réduite pour les écrans très petits
                height: auto;
            }
        }
    }

    @media (max-width: 425px) {
        .footer-section {
            text-align: center;
            margin: 10px 0; // Marges pour espacement vertical
        }

        .social-icons {
            justify-content: center; // Centrer les icônes des réseaux sociaux sur très petits écrans
        }

        .footer-bottom {
            margin-top: 10px;

            img {
                max-width: 60px; // Taille de l'image ajustée pour les écrans de petite taille
                height: auto;
            }
        }
        ul{
            padding-inline-start: 0px;
        }
    }
}
