.container-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    background-color: #f8f9fa;
    padding: 20px;
}

.container-error h1 {
    font-size: 4em;
    margin-bottom: 20px;
}

.container-error h2 {
    margin-bottom: 20px;
}

.container-error .link {
    color: $background-color;
    text-decoration: none;
}

.container-error .link:hover {
    text-decoration: underline;
}