.isAdmin{
    justify-content: center;
    display: flex;
    

    background-color: $primary-color;
    a {
        color: white;

    }

}
.isAdmin a:hover{
     color: #c0c0c0; 
}