.filters {
    margin: 20px 0;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .filters h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
  }
  
  .filter-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .filter-row select,
  .filter-row input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .filter-row select {
    min-width: 150px;
  }
  
  .filter-row input {
    flex: 1;
  }
  
  .filter-row button {
    background-color: #0077a6;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .filter-row button:hover {
    background-color: #005f85;
  }
  
  .filters button {
    background-color: #0077a6;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .filters button:hover {
    background-color: #005f85;
  }
  
  .filters .add-filter-button {
    margin-top: 10px;
  }
  