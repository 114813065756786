.contact-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.contact-form-container p {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #666;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form h3 {
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    background-color: #66afe9;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-control:focus {
    border-color: #66afe9;
    outline: none;
}

.btn {
    padding: 10px 15px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.btn-primary {
    background-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
}
