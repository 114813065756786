.dashboard-container {
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.01);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }

    h1 {
        color: #333;
        font-size: 2.5em;
        margin-bottom: 20px;
        transition: color 0.3s ease;

        &:hover {
            color: #007bff;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;

        li {
            margin: 10px 0;
            padding: 20px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;

            &:hover {
                transform: scale(1.01);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            }

            a {
                text-decoration: none;
                color: #007bff;
                font-size: 1.2em;
                transition: color 0.3s ease;

                &:hover {
                    color: #0056b3;
                }
            }
        }
    }
}