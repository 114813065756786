.mentions-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    line-height: 1.6;
}

.mentions-container h2 {
    color: #444;
    margin-top: 30px;
}

.mentions-container p {
    margin: 20px 0;
    text-align: justify;
}

.mentions-container a {
    color: #0066cc;
    text-decoration: none;
}

.mentions-container a:hover {
    text-decoration: underline;
}