.newpass-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    background-color: #f8f9fa;
    padding: 20px;
}

.newpass-container h2 {
    margin-bottom: 20px;
}

.newpass-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.newpass-container label {
    font-weight: bold;
    margin-top: 20px;
}

.newpass-container input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
}

.newpass-container button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: $background-color;
    cursor: pointer;
    margin-top: 20px;
}

.newpass-container button:hover {
    background-color: #005b9f;
}