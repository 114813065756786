
#HideShowPassword {
    background-color: transparent;
    color: black;
    width: 20px;
    margin-top: 6px;
    margin-left: -27px;
    right: 10px;
    position: relative;
    z-index: 2;
}

.password-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

