.Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    .formulaire {
        display: flex;
        flex-direction: column;
        width: 40%;
        background-color: $primary-color;
        padding: 3%;
        border-radius: 20px;
        margin: 0 auto;
        height: 600px;
        justify-content: space-between;

        fieldset {
            border-color: black;
        }

        input,
        select {
            background-color: $background-color;
            border-radius: 5px;
            border: none;
        }

        .address {
            display: flex;
            flex-direction: column;
            margin-top: 5%;
            height: 250px;

        }

        input {
            height: 20px;
        }

        select {
            height: 30px;
        }

        .error-date {
            margin-top: 1px;
            margin-bottom: 0;
            font-style: italic;
        }
    }

    .button-save {
        background-color: $primary-color;
        padding: 1%;
        border-radius: 20px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        width: 20%;
        text-align: center;
        margin-top: 3%;
        margin-bottom: 3%;
        border: none
    }
}

@media screen and (max-width:1700px) {
    .Form {
        .formulaire {
            width: 50%;
        }
    }
}

@media screen and (max-width:1100px) {
    .Form {
        .formulaire {
            width: 60%;
        }

    }
}

@media screen and (max-width:500px) {
    .Form {
        .formulaire {
            width: 80%;
        }

    }
}