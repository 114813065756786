.admin-create-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.input-field {
    margin: 10px 0;
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.create-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.url-container {
    display: block;
    align-items: center;
    margin-top: 20px;
}

.url-display {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.copy-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}