table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 15px; 
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover { 
  background-color: #ddd;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: $primary-color;
  color: white;
}

button { 
  background-color: $primary-color;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

button:hover { 
  background-color: darken($primary-color, 10%);
}
.filter-container {
font-size: medium;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.filter-container select,
.filter-container input {
  width: 30%; 
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.filter-capsule {
  display: list-item;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter-capsule > * {
  flex: 1 0 45%; 
  margin: 5px 0; 
}

.table-container {
  overflow:scroll;
}

.importCsv-container {
  margin:10px;
}

/* appareils mobiles */
@media (max-width: 768px) {
/*   table, thead, tbody, th, td, tr {
    display: block;
  } 
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  } 
  tr { border: 1px solid #ccc; }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 5%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-column);

    color: #000;
    font-weight: bold;
  } */

  .AdminUserscontainer {
    overflow-x: auto; 
  }

  button {
    padding: 10px; 
    font-size: 14px; 
  }

}