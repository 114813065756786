.Header {
    padding: 20px; 
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    color: #ffffff;
    transition: background-color 0.3s, box-shadow 0.3s;

    .logo {
        border-radius: 25px;
        max-width: 200px; 
        height: auto;
    }

    .Deco {
        background: #e6f7ff; 
        color: #005b9f; 
     
        border: 2px solid $background-color; 
        border-radius: 20px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        transition: background 0.5s, color 0.5s, box-shadow 0.5s, transform 0.5s; 
  
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
        display: inline-block;
        position: relative;
        overflow: hidden;
    }
    
    .Deco::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3); 
 
        transition: all 0.8s ease; 
 
        transform: translate(-50%, -50%) scale(0);
        border-radius: 50%;
        pointer-events: none;
    }
    
    .Deco:hover::before {
        transform: translate(-50%, -50%) scale(1.2);

    }
    
    .Deco:hover {
        background: linear-gradient(135deg, #ffffff, #f0f0f0); 
        color: $background-color; 
      
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 

    }
    
    .Deco:active {
        background: #cceeff; 
        color: #004080;
     
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 0 3px rgba(0, 0, 0, 0.1); 
 
        transform: translateY(2px);
    }
    
    
    
    
    .navigation {
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        margin-right: 15px;
        font-size: 18px;
        font-weight: bold;
    }
    

    .nav {
        color: $background-color; 
        transition: color 0.3s ease, border-bottom 0.3s ease; 
        text-decoration: none;
        margin: 0px 44px;
        
    }
    
    .nav:hover {
        color: #c0c0c0; 
    }
    
    .nav-active {
        margin: 40px;
        color: $background-color; 
        border-bottom: 2px solid #fff; 
        padding-bottom: 2px;
        transition: color 0.3s ease, border-bottom 0.3s ease; 
    }
    
    .nav-active:hover {
        color: #c0c0c0; 
        border-bottom: 2px solid #c0c0c0; 
    }
    
 
    .nav + .nav {
        margin-left: 10px; 
    }
    
    .nav:hover {
        color: #c0c0c0; 
    }
    
    
    

@media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;

    .logo {
        height: 70px;
    }

    .navigation {
        margin: auto;
        width: 100%;
        padding: 10px 0;

        .nav {
            display: block;
            padding: 10px 0;
            text-align: center;
            margin: 0px 0px;

            & + .nav {
                margin-top: 10px;
            }
            
        }
    }
}

@media screen and (max-width: 425px) {
    .navigation {
        font-size: 15px;
    }
    .nav-active {
        margin: 0px;
}
}

@media screen and (max-width: 375px) {
    .navigation {
        font-size: 12px;
    }
}
}