.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top :30px;
    h1 {
        font-size: 1rem; // Augmente la taille de la police
        margin: 20px 0; // Ajoute de l'espace autour du titre
        color: #333; // Change la couleur de la police
        text-align: center; // Centre le texte
    }


form {
    background-color:white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

label {
    background-color: white;
    display: grid;
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
}

input[type="text"] {
    
    width: 80%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}

input[type="checkbox"] {
    margin-left: 10px;
}

button {
    padding: 10px 20px;
    background-color: $background-color; // Choisissez une couleur qui contraste avec le reste de votre page
    color: #fff;
    border: 2px solid #000; // Ajoute une bordure noire autour du bouton
    border-radius: 5px;
    cursor: pointer;
transition: background-color 0.3s ease; // Ajout de la transition
}
.activity-container td {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}
button:hover {
    background-color: $primary-color;
    transform: scale(1.05); // Ajout de l'effet de survol
}

button:active {
    background-color: #004085;
}

button:last-of-type {
    background-color: #dc3545;
    margin-left: 10px;
}

button:last-of-type:hover {
    background-color: #c82333;
    transform: scale(1.05); // Ajout de l'effet de survol
}

button:last-of-type:active {
    background-color: #bd2130;
}
.button-container {
    grid-column: 1/-1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0; // Ajout de l'espacement
}


}